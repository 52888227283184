import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./product.css";
import Header1 from "../../Common/Layout/Header/Header1";
import client from "../../Common/Client/Client";
import Button from "@mui/material/Button";
import Icons from "../../Common/Icons/Icons";


const Products = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const respose = await client.get("/product/get-product");
      setProducts(respose.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="sub_page">
        <Header1 />
        <Icons/>
        <div
          className="heading_container heading_center"
          style={{ marginTop: "50px" }}
          data-aos="zoom-in"
        >
          <h2 style={{ color: "black" }}>Our Products</h2>
          <p className="col-md-10 mx-auto px-0" style={{ color: "black" }}>
            Meditech Disposables offers high-quality, sterile products designed
            for maximum safety and convenience in medical procedures. Our range
            includes durable, single-use items that ensure a clean and efficient
            clinical environment
          </p>
        </div>
        <div className="product-show" data-aos="zoom-in">
          {products.map((productValue, index) => {
            return (
              <div
                key={productValue._id}
                onClick={() => {
                  navigate("/products-details", {
                    state: productValue,
                  });
                }}
                className="box"
                style={{
                  color: "white",
                 
                  boxShadow: " 10px 10px 15px gray",
                  borderRadius: "20px",
                  height: "260px",
                  position: "relative", // add this
                  overflow: "hidden", // add this
                }}
              >
                <div className="img-box">
                  <img
                    src={productValue.mainImage}
                    alt=""
                    width="100%"
                    height="200px"
                    style={{
                      borderTopRightRadius: "20px",
                      borderTopLeftRadius: "20px",
                    }}
                  />
                </div>
                <div className="detail-boxs" style={{
                  backgroundColor:"#09a5ba"
                }}>
                  <h5 style={{ color: "white" }}>{productValue.name}</h5>
                </div>
                <Button variant="outlined"
                  className="click-me-btn"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  Click me
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default Products;
