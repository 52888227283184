import React, { Fragment,useState,useEffect } from "react";
// import headerImage from "../../../../Assets/images/hero-bg.png";
import { Link ,useLocation } from "react-router-dom";
import client from "../../Client/Client";
import { MdPhoneInTalk } from "react-icons/md";
import { IoMdMail } from "react-icons/io";

const Header = () => {
  const [data, setData] = useState([]);
  const location=useLocation()

  useEffect(() => {
    getFetch();
  }, []);

  const getFetch = async () => {
    try {
      const response = await client.get("/contact/get-contact");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Fragment>
      <div className="hero_area">
      {data.map((value, index) => {
        return (
          <div className="infromation-header"  >
            <div className="headerContact" data-aos="zoom-in"  onClick={() => {
                            window.location.href = `tel:+91 ${value.phone}`;
                          }}>
              <p  style={{
                fontSize:"20px"
              }}>
                <MdPhoneInTalk />
              </p>
              <p>
                {value.phone}
              </p>
            </div>
            <div className="headerContact" data-aos="zoom-in"   onClick={() => {
                          window.location.href = `mailto:${value.email}`;
                        }}>
              <p style={{
                fontSize:"20px"
              }}>
                <IoMdMail />
              </p>
              <p>
                {value.email}
              </p>
            </div>
          </div>
        );
      })}
        <div className="hero_bg_box">
          <img src="Assets/images/hero-bg.png" alt="" />
        </div>

        <header className="header_section"  data-aos="zoom-in">
          <div className="container">
            <nav className="navbar navbar-expand-lg custom_nav-container ">
              <Link className="navbar-brand" to="/">
                <span>
                  <img
                    src="Assets/images/logo.png"
                    alt=""
                    width="100px"
                    height="100px"
                  />
                </span>
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className=""> </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/">
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/about">
                      About
                    </Link>
                  </li>
                  <li className={`nav-item ${location.pathname === '/products' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/products">
                      Products
                    </Link>
                  </li>
                  
                  <li className={`nav-item ${location.pathname === '/booking' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/booking">
                      Booking
                    </Link>
                  </li>
            


                  <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/contact">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        <section className="slider_section"  data-aos="zoom-in">
          <div
            id="customCarousel1"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="detail-box">
                        <h1>Innovative Disposables for Superior Healthcare.</h1>
                        <p>
                          We deliver cutting-edge medical disposables designed
                          to enhance patient safety and streamline care. Our
                          products combine advanced technology with top-quality
                          standards to support healthcare professionals in
                          achieving optimal outcomes.
                        </p>
                        <div className="btn-box">
                          <Link to="/booking" className="btn1">
                          Purchase Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item ">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="detail-box">
                        <h1>Excellence in Every Disposable Solution</h1>
                        <p>
                          Our products set the standard for quality and
                          reliability in medical disposables, ensuring
                          consistent performance and safety. Each solution is
                          crafted with meticulous attention to detail to support
                          superior patient care and healthcare efficiency.
                        </p>
                        <div className="btn-box">
                          <Link to="/booking" className="btn1">
                          Purchase Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="container ">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="detail-box">
                        <h1>Reliable Solutions for Optimal Patient Care</h1>
                        <p>
                          We provide dependable medical disposables designed to
                          ensure consistent performance and safety. Our
                          solutions are tailored to support healthcare
                          professionals in delivering the highest quality care
                          to patients. esse amet tempora quibusdam laudantium,
                          laborum eaque magnam fugiat hic? Esse dicta aliquid
                          error repudiandae earum suscipit fugiat molestias,
                          veniam, vel architecto veritatis delectus repellat
                          modi impedit sequi.
                        </p>
                        <div className="btn-box">
                          <Link to="/booking" className="btn1">
                          Purchase Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ol className="carousel-indicators">
              <li
                data-target="#customCarousel1"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#customCarousel1" data-slide-to="1"></li>
              <li data-target="#customCarousel1" data-slide-to="2"></li>
            </ol>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Header;
