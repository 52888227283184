import React from 'react'
import notFound from '../../../Assets/Image/not.png'
import { Link } from "react-router-dom";
import './NotFound.css'

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img src={notFound} alt="Page not found" className="not-found-image" />
      <h1>Oops! Page Not Found</h1>
      <Link to="/" className="home-link">
        Go to Home
      </Link>
    </div>
  )
}

export default NotFound