import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import { MdPhoneInTalk } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { useEffect, useState } from "react";
import client from "../../Client/Client";

const Header1 = () => {
  const [data, setData] = useState([]);
  const location=useLocation()

  useEffect(() => {
    getFetch();
  }, []);

  const getFetch = async () => {
    try {
      const response = await client.get("/contact/get-contact");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="sub_page">
      {data.map((value, index) => {
        return (
          <div className="infromation-header"  >
            <div className="headerContact" data-aos="zoom-in"  onClick={() => {
                            window.location.href = `tel:+91 ${value.phone}`;
                          }}>
              <p  style={{
                fontSize:"20px"
              }}>
                <MdPhoneInTalk />
              </p>
              <p>
                {value.phone}
              </p>
            </div>
            <div className="headerContact" data-aos="zoom-in"   onClick={() => {
                          window.location.href = `mailto:${value.email}`;
                        }}>
              <p style={{
                fontSize:"20px"
              }}>
                <IoMdMail />
              </p>
              <p>
                {value.email}
              </p>
            </div>
          </div>
        );
      })}
      <div className="hero_area">
        <header
          className="header_section"
          data-aos="zoom-in"
          style={{
            padding: "0px",
          }}
        >
          <div className="container">
            <nav className="navbar navbar-expand-lg custom_nav-container ">
              <Link className="navbar-brand" to="/">
                <span>
                  <img
                    src="Assets/images/logo.png"
                    alt=""
                    width="60px"
                    height="70px"
                  />
                </span>
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className=""> </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                   <ul className="navbar-nav">
                  <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/">
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/about">
                      About
                    </Link>
                  </li>
                  <li className={`nav-item ${location.pathname === '/products' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/products">
                      Products
                    </Link>
                  </li>
                  
                  <li className={`nav-item ${location.pathname === '/booking' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/booking">
                      Booking
                    </Link>
                  </li>
            


                  <li className={`nav-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/contact">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header1;
