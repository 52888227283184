import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../Common/Layout/Header/Header";
import { MdLightMode } from "react-icons/md";
import { GoGoal } from "react-icons/go";
import { FaHandHoldingHeart } from "react-icons/fa";
import "./Home.css";
import client from "../../Common/Client/Client";
import Icons from "../../Common/Icons/Icons";

const Home = () => {
  const [products, setProducts] = useState([]);

  const sliceProducts = products.slice(0, 4);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const respose = await client.get("/product/get-product");
      setProducts(respose.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <Header />
      <Icons />
      <section className="about_section layout_margin-bottom">
          <div className="homegrid" data-aos="zoom-in">
       
              <div className="detail-box" id="details">
                <div className="heading_container" id="detail">
                  <h2>
                    <MdLightMode className="icon-home" />
                    <span style={{
                      fontSize: "20px",
                      marginLeft:"10px"
                    }}>Our Vision</span>
                  </h2>
                </div>
                <p>
                  To be a global leader in medical disposables, setting the
                  benchmark for quality and innovation in healthcare products.
                  We aspire to transform patient care and support medical
                  professionals through cutting-edge solutions and sustainable
                  practices, fostering a healthier world for all.
                </p>
              </div>
    
       
              <div className="detail-box" id="details">
                <div className="heading_container" id="detail">
                  <h2>
                    <GoGoal className="icon-home"/>
                    <span style={{
                      fontSize: "20px",
                      marginLeft:"10px"
                    }}>Our Mission</span>
                  </h2>
                </div>
                <p>
                  To deliver high-quality, reliable, and cost-effective medical
                  disposables that enhance patient care and support healthcare
                  professionals. We are committed to safety and performance, and
                  continuous improvement, striving to positively impact
                  healthcare outcomes through innovation and excellence.
                </p>
              </div>
       
              <div className="detail-box" id="details">
                <div className="heading_container" id="detail">
                  <h2>
                    <FaHandHoldingHeart className="icon-home" />
                    <span style={{
                      fontSize: "20px",
                      marginLeft:"10px"
                    }}>Our Value</span>
                  </h2>
                </div>
                <p>
                  We are dedicated to providing top-quality, reliable medical
                  disposables that enhance patient care. Innovation drives our
                  continuous improvement to the meet evolving healthcare needs.
                  We prioritize integrity, customer satisfaction, and excellence
                  in every aspect of our operations.
                </p>
          
            </div>
        </div>
      </section>
      <section
        class="ttm-row service-section ttm-bgcolor-grey position-relative z-index-2 clearfix"
        data-aos="zoom-in"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text container">
                <div className="title-header heading_container heading_center">
                  <h5>INDUSTRIES &amp; PRODUCTION</h5>
                  <h2>OUR FOOT PRINTS FOR SUCCESSFUL OUTCOME</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row ouroutcomes">
  <div className="col-lg-12">
    <div className="featuredbox-number">
      <div className="ouroutcomes-item">
        <div className="featured-icon-box icon-align-top-content style5">
          <div className="featured-icon">
            <img src="Assets/images/05.jpg" alt="" />
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h3>CUSTOMER REQUIREMENTS</h3>
            </div>
            <div className="featured-desc">
              <p>
                Deals with the uncover needs, problems & desires of the customers to meet the essential
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ouroutcomes-item">
        <div className="featured-icon-box icon-align-top-content style5">
          <div className="featured-icon">
            <img src="Assets/images/06.jpg" alt="" />
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h3>SELECTION OF RAW MATERIALS</h3>
            </div>
            <div className="featured-desc">
              <p>
                Use of High graded raw materials acquired from the certified approved suppliers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ouroutcomes-item">
        <div className="featured-icon-box icon-align-top-content style5">
          <div className="featured-icon">
            <img src="Assets/images/07.jpg" alt="" />
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h3>PRODUCTION EFFICACY</h3>
            </div>
            <div className="featured-desc">
              <p>
                Competent staffs with well-equipped machineries meeting the regulations of ISO 13485
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ouroutcomes-item">
        <div className="featured-icon-box icon-align-top-content style5">
          <div className="featured-icon">
            <img src="Assets/images/08.jpg" alt="" />
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h3>MEDICAL GRADED PACKAGING & STERILIZATION</h3>
            </div>
            <div className="featured-desc">
              <p>
                Medical graded packaging adopts ISO 11607 followed by ETO sterilization in accordance with ISO 11135
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </div>
      </section>
      <div
        className="certification-container"
        data-aos="zoom-in"
        style={{ marginTop: "30px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 heading_container heading_center">
              <h2 className=" ">Certifications</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "20px" }}>
              <div className="certification-box " >
                <img src="Assets/images/certificate.jpg" alt="" width="100%"  style={{
                  boxShadow:" rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                }} className="homeImage"/>
              </div>
            </div>
            <div className="col-md-6" style={{ marginTop: "20px" }}>
              <div className="certification-box">
                <img src="Assets/images/test.jpg" alt="" width="100%" style={{
                  boxShadow:" rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                }} className="homeImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="doctor_section layout_padding products">
        <div className="container" data-aos="zoom-in">
          <div className="heading_container heading_center">
            <h2 style={{ color: "black" }}>Our Products</h2>
            <p className="col-md-10 mx-auto px-0" style={{ color: "black" }}>
              Meditech Disposables offers high-quality, sterile products
              designed for maximum safety and convenience in medical procedures.
              Our range includes durable, single-use items that ensure a clean
              and efficient clinical environment
            </p>
          </div>
          <div className="row">
            {sliceProducts.map((value, index) => {
              return (
                <div className="col-sm-6 col-lg-3 mx-auto " key={index}>
                  <div
                    className="box"
                    style={{
                      color: "white",
                      backgroundColor: "#62d2a2",
                      boxShadow: "10px 10px 15px gray",
                    }}
                  >
                    <Link to="/products">
                      <div className="img-box">
                        <img src={value.mainImage} alt="" height="250px" />
                      </div>
                      <div className="detail-box">
                        <h5 style={{ color: "white" }}>{value.name}</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="btn-box">
            <Link to="/products">View All</Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Home;
