import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import client from "../../Client/Client";
import { FaArrowUp } from "react-icons/fa";

const Footer = () => {
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);

  // Get the first 5 products
  const displayedProducts = products.slice(0, 5);

  useEffect(() => {
    fetchData();
    getFetch();
  }, []);

  const fetchData = async () => {
    try {
      const respose = await client.get("/product/get-product");
      setProducts(respose.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getFetch = async () => {
    try {
      const response = await client.get("/contact/get-contact");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Fragment>
      <footer className="footer_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 footer_col">
              <nav className="navbar navbar-expand-lg custom_nav-container " style={{
                display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"space-around"
              }}>
                <Link className="navbar-brand" to="/">
                 
                    <img
                      src="Assets/images/logo.png"
                      alt=""
                      width="70px"
                      height="70px"
                    />
                   
                </Link>
              
              </nav>
              <div className="footer_detail" style={{ padding: "20px" }}>
                <p>
                  Sri Vignesh Meditech Disposables provides high-quality,
                  reliable medical disposable products designed for safety and
                  efficiency in healthcare settings.
                </p>
              </div>
              <div className="cards">
                {data.map((item, index) => {
                  return (
                    <div key={index} className="cards">
                      <Link
                        to="#"
                        className="socialContainer containerFive"
                        onClick={() => {
                          window.location.href = `mailto:${item.email}`;
                        }}
                      >
                        <MdEmail style={{ color: "white", fontSize: "20px" }} />
                      </Link>

                      <Link
                        to="#"
                        className="socialContainer containerFour"
                        onClick={() => {
                          window.open(`https://wa.me/${item.whatsapp}`);
                        }}
                      >
                        <FaWhatsapp
                          style={{ color: "white", fontSize: "20px" }}
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-6 col-lg-3  footer_col f-p" >
              <div className="footer_link_box footerpage">
                <h4 className="mb-3">Pages</h4>
                <div
                  className="footer_links"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0px",
                  }}
                >
                  <Link className="active" to="/">
                    Home
                  </Link>
                  <Link className="" to="/about">
                    About
                  </Link>
                  <Link className="" to="/products">
                    Products
                  </Link>
                  <Link className="" to="/booking">
                    Booking
                  </Link>
                  <Link className="" to="/contact">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 footer_col ">
              <div className="footer_link_box">
              <h4 className="mb-3">Our Products</h4>
                <div
                  className="footer_links"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {displayedProducts.map((value, index) => {
                    return (
                      <Link className="active" to="/products" key={index} style={{
                        textTransform:"capitalize"
                      }}>
                        {value.name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 footer_col">
              <div className="footer_contact">
              <h4 className="mb-3">Reach at</h4>

                {data.map((value, index) => {
                  return (
                    <div key={index} className="footer_link_box">
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                        }}
                      >
                        <span>
                          <IoLocationOutline
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </span>

                        <span
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {value.address}
                        </span>
                      </div>

                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                        }}
                      >
                        <span>
                          <MdOutlinePhoneInTalk
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </span>
                        <span
                          onClick={() => {
                            window.location.href = `tel:+91 ${value.phone}`;
                          }}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {value.phone}
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                        }}
                        onClick={() => {
                          window.location.href = `mailto:${value.email}`;
                        }}
                      >
                        <span>
                          <MdOutlineMail
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </span>
                        <span
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {value.email}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="gototop-center">
            <p>
              <span onClick={()=>{
                 window.scrollTo({top:0,behavior:"auto"});
              }}>
                <FaArrowUp />
              </span>
            </p>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
