import React from "react";
import Home from "../../Pages/Home/Home";
import About from "../../Pages/About/About";
import Contact from "../../Pages/Contact/Contact";
import Products from "../../Pages/Products/Products";
import Booking from "../../Pages/Booking/Booking";
import ProductsDetails from "../../Pages/Products/Products-details";
import NotFound from "../../Pages/Notfound/NotFound";

const route = [
  { path: "/", element: <Home />,exact:true},
  { path: "/about", element: <About />,exact:true },
  { path: "/products", element: <Products />,exact:true },
  { path:"/products-details",element:<ProductsDetails/>},
  { path: "/booking", element: <Booking /> ,exact:true},
  { path: "/contact", element: <Contact />,exact:true },
  {path:"*",element:<NotFound/>},

];

export default route;
