import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Header1 from "../../Common/Layout/Header/Header1";
import "./Booking.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import client from "../../Common/Client/Client";
import toast from "react-hot-toast";
import Icons from "../../Common/Icons/Icons";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";



const steps = ["Personal Details", "Product Details", "Summary"];

export default function Booking() {
  const [products, setProducts] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [address, setAddress] = React.useState({
    doorno: "",
    street: "",
    landmark: "",
    area: "",
    district: "",
    state: "",
    pincode: "",
  });
  const [error, setError] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    product: "",
    quantity: "",
    doorno: "",
    street: "",
    landmark: "",
    area: "",
    district: "",
    state: "",
    pincode: "",
  });
  const [checkboxState, setCheckBoxState] = React.useState([]);
  const navigate = useNavigate();
  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const respose = await client.get("/product/get-product");
      setProducts(respose.data);
    } catch (error) {
      console.log(error);
    }
  };

  const isStepSkipped = (step) => skipped.has(step);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        [name]: `${name} field is required`,
      }));
    }
  };

  const handleBooking = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let fulldata = {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        address: address,
        purchase: checkboxState,
      };
      // Convert address object to a string
      const addressString = Object.entries(address)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
      const productsString = checkboxState
        .map(({ productName, quantity }) => `${productName}-${quantity}`)
        .join(", ");
      const response = await client.post("/booking/submit-booking", fulldata);
      if (response.status === 201) {
        const formData = new FormData();
        formData.append("Name", fulldata.name);
        formData.append("Email", fulldata.email);
        formData.append("Phone Number", fulldata.phoneNumber);
        formData.append("Address", addressString);

        formData.append("Product Details", productsString);

        formData.append("access_key", "a8fec308-f200-40a6-87b4-93f9679c44d5");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        }).then((res) => res.json());

        if (res.success) {
          setLoading(false);
          toast.success("Thank you! Your order has been successfully placed");
          navigate("/");
        }
      }
      handleNext();
    } catch (err) {
      console.log(err);
    }
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      name: "",
      email: "",
      product: "",
      quantity: "",
      phoneNumber: "",
    });
    setAddress({
      doorno: "",
      street: "",
      landmark: "",
      area: "",
      district: "",
      state: "",
      pincode: "",
    });
    setCheckBoxState([]);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setAddress({ ...address, [name]: value });
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "name") {
      if (fieldValue.length < 3) {
        message = `${fieldName} is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "email") {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,}@[a-zA-Z-]+\.[a-zA-Z-]{2,}$/;
      if (!emailRegex.test(fieldValue)) {
        message = `${fieldName} is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "phoneNumber") {
      // Remove non-numeric characters for validation
      const numericValue = fieldValue.replace(/[^0-9]/g, "");

      if (numericValue.length < 10) {
        message = "Phone number needs 10 characters";
      } else if (numericValue.length > 10) {
        message = "Phone number is too long";
      } else {
        const prefix = parseInt(numericValue.slice(0, 2), 10);
        if (!(prefix >= 63 && prefix <= 99)) {
          message = "Invalid Phone Number";
        } else {
          message = "";
        }
      }
    }

    if (fieldName === "doorno") {
      if (fieldValue.length < 2) {
        message = "Doorno is invalid";
      } else {
        message = "";
      }
    }

    if (fieldName === "street") {
      if (fieldValue.length < 3) {
        message = "street is invalid";
      } else {
        message = "";
      }
    }

    if (fieldName === "landmark") {
      if (fieldValue === "") {
        message = "";
      } else if (fieldValue.length < 3) {
        message = "Land Mark is invalid";
      } else {
        message = "";
      }
    }

    if (fieldName === "area") {
      if (fieldValue.length < 3) {
        message = "Area is invalid";
      } else {
        message = "";
      }
    }

    if (fieldName === "district") {
      if (fieldValue.length < 3) {
        message = "District is invalid";
      } else {
        message = "";
      }
    }

    if (fieldName === "state") {
      if (fieldValue.length < 3) {
        message = "State is invalid";
      } else {
        message = "";
      }
    }

    if (fieldName === "pincode") {
      if (fieldValue.length < 6) {
        message = "Pincode must need 6 number";
      } else if (fieldValue.length > 6) {
        message = "Pincode much larger";
      } else {
        message = "";
      }
    }

    return { message: message };
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setCheckBoxState((prevState) => {
      if (checked) {
        return [
          ...prevState,
          { productName: name, quantity: 1, status: "Not Delivered" },
        ];
      } else {
        return prevState.filter((item) => item.productName !== name);
      }
    });
  };

  const handleQuality = (e, name) => {
    const value = e.target.value;
    setCheckBoxState((prevState) =>
      prevState.map((item) =>
        item.productName === name ? { ...item, quantity: value } : item
      )
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (activeStep === 1) {
      if (checkboxState.length === 0) {
        toast.error("Select one product");
      } else {
        handleNext();
      }
    } else {
      handleNext();
    }
  };

  const handleDown=(e)=>{
    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  }

  return (
    <div>
      <Header1 />
      <Icons />
      <div className="stpperform" data-aos="zoom-in">
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps} sx={{
                  color:"white"
                }}>
                  <StepLabel sx={{
                         color:"white"
                         
                        }} >{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <div className="personalDetails">
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <div className="personalDetails">
                  <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Personal Details
                    </Typography>
                    <div
                      className="details-form"
                      style={{
                        color: "white",
                      }}
                    >
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white", // Default border color
                            },
                            "&:hover fieldset": {
                              borderColor: "white", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white", // Border color when focused
                            },
                          },
                          mb: 2,
                          mr:1,
                          color: "white",
                        }}
                        InputProps={{
                          sx: {
                            color: "white",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: "white",
                          },
                        }}
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleFormChange}
                        onBlur={handleBlur}
                        error={!!error.name}
                        helperText={error.name}
                        required
                        inputProps={{ maxLength: 30 }}
                        className="tet"
                        onKeyDown={(e) => {
                          handleDown(e)
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                            " "
                          ];
                          const allowedCharPattern = /^[A-Za-z._-]$/;

                          // Check if the pressed key is not allowed
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault(); // Prevent the default action of the disallowed key
                          }
                        }}
                      />

                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white", // Default border color
                            },
                            "&:hover fieldset": {
                              borderColor: "white", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white", // Border color when focused
                            },
                          },
                          mb: 2, mr:1,
                          color: "white",
                        }}
                        InputProps={{
                          sx: {
                            color: "white",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: "white",
                          },
                        }}
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleFormChange}
                        onBlur={handleBlur}
                        error={!!error.email}
                        helperText={error.email}
                        required
                        className="tet"
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[0-9a-z._@-]$/;

                          // Check if the pressed key is not allowed
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault(); // Prevent the default action of the disallowed key
                          }
                        }}
                      />

                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white", // Default border color
                            },
                            "&:hover fieldset": {
                              borderColor: "white", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white", // Border color when focused
                            },
                          },
                          mb: 2, mr:1,
                          color: "white",
                        }}
                        InputProps={{
                          sx: {
                            color: "white",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            color: "white",
                          },
                        }}
                        label="Phone Number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleFormChange}
                        onBlur={handleBlur}
                        error={!!error.phoneNumber}
                        helperText={error.phoneNumber}
                        inputProps={{ maxLength: 10 }}
                        className="tet"
                        required
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[0-9]$/;

                          // Check if the pressed key is not allowed
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault(); // Prevent the default action of the disallowed key
                          }
                        }}
                      />
                    </div>
                    <div className="address">
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Contact Details
                      </Typography>
                      <div className="details-forms">
                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white", // Default border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            mb: 2, mr:1,
                            color: "white",
                          }}
                          InputProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          label="Door No"
                          name="doorno"
                          required
                          value={address.doorno}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!error.doorno}
                          helperText={error.doorno}
                          inputProps={{ maxLength: 10 }}
                          className="test"
                          onKeyDown={(e) => {
                            handleDown(e)
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                              "Tab",
                            ];
                            const allowedCharPattern = /^[0-9A-Za-z/-]$/;

                            // Check if the pressed key is not allowed
                            if (
                              !allowedKeys.includes(e.key) &&
                              !allowedCharPattern.test(e.key)
                            ) {
                              e.preventDefault(); // Prevent the default action of the disallowed key
                            }
                          }}
                        />

                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white", // Default border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            mb: 2, mr:1,
                            color: "white",
                          }}
                          InputProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          label="Street Name"
                          name="street"
                          value={address.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!error.street}
                          helperText={error.street}
                          inputProps={{ maxLength: 20 }}
                          onKeyDown={(e) => {
                            handleDown(e)
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                              "Tab",
                              "space",
                            ];
                            const allowedCharPattern = /^[0-9A-Za-z/':;.,()-]$/;
                            const isSpaceKey = e.key === " ";

                            // Check if the pressed key is not allowed
                            if (
                              !allowedKeys.includes(e.key) &&
                              !allowedCharPattern.test(e.key) &&
                              !isSpaceKey
                            ) {
                              e.preventDefault(); // Prevent the default action of the disallowed key
                            }
                          }}
                          required
                          className="test"
                        />

                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white", // Default border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            mb: 2, mr:1,
                            color: "white",
                          }}
                          InputProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          label="Area"
                          name="area"
                          value={address.area}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!error.area}
                          inputProps={{ maxLength: 20 }}
                          helperText={error.area}
                          required
                          className="test"
                          onKeyDown={handleDown}
                        />
                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white", // Default border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            mb: 2, mr:1,
                            color: "white",
                          }}
                          InputProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          label="Land Mark"
                          name="landmark"
                          error={!!error.landmark}
                          helperText={error.landmark}
                          value={address.landmark}
                          onChange={handleChange}
                          inputProps={{ maxLength: 20 }}
                          className="test"
                          onKeyDown={handleDown}
                        />
                      </div>
                      <div className="details-form">
                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white", // Default border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            mb: 2, mr:1,
                            color: "white",
                          }}
                          InputProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          label="District"
                          name="district"
                          value={address.district}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!error.district}
                          helperText={error.district}
                          required
                          inputProps={{ maxLength: 20 }}
                          className="tet"
                          onKeyDown={(e) => {
                            handleDown(e)
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "Space",
                              "ArrowRight",
                              "Delete",
                              "Tab",
                            ];
                            const allowedCharPattern = /^[a-zA-Z/]$/;
                            const isSpaceKey = e.key === " ";

                            // Check if the pressed key is not allowed
                            if (
                              !allowedKeys.includes(e.key) &&
                              !allowedCharPattern.test(e.key) &&
                              !isSpaceKey
                            ) {
                              e.preventDefault(); // Prevent the default action of the disallowed key
                            }
                          }}
                        />

                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white", // Default border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            mb: 2, mr:1,
                            color: "white",
                          }}
                          InputProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          label="State"
                          name="state"
                          type="text"
                          value={address.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!error.state}
                          helperText={error.state}
                          required
                          inputProps={{ maxLength: 20 }}
                          className="tet"
                          onKeyDown={(e) => {
                            handleDown(e)
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "Space",
                              "ArrowRight",
                              "Delete",
                              "Tab",
                            ];
                            const allowedCharPattern = /^[a-zA-Z/]$/;
                            const isSpaceKey = e.key === " ";

                            // Check if the pressed key is not allowed
                            if (
                              !allowedKeys.includes(e.key) &&
                              !allowedCharPattern.test(e.key) &&
                              !isSpaceKey
                            ) {
                              e.preventDefault(); // Prevent the default action of the disallowed key
                            }
                          }}
                        />

                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white", // Default border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            mb: 2, mr:1,
                            color: "white",
                          }}
                          InputProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: "white",
                            },
                          }}
                          label="Pincode"
                          name="pincode"
                          value={address.pincode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!error.pincode}
                          helperText={error.pincode}
                          inputProps={{ maxLength: 6 }}
                          required
                          className="tet"
                          onKeyDown={(e) => {
                            handleDown(e)
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                              "Tab",
                            ];
                            const allowedCharPattern = /^[0-9]$/;

                            // Check if the pressed key is not allowed
                            if (
                              !allowedKeys.includes(e.key) &&
                              !allowedCharPattern.test(e.key)
                            ) {
                              e.preventDefault(); // Prevent the default action of the disallowed key
                            }
                          }}
                        />
                      </div>
                    </div>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button type="submit" variant="contained">
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </Box>
                  </Box>
                </div>
              ) : activeStep === 1 ? (
                <div className="personalDetails">
                  <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Product Details
                    </Typography>
                    <div className="detailsform">
                      {products.map((value, index) => {
                        return (
                          <div key={value._id} className="checkboxform">
                            <div>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={value.name}
                                      checked={checkboxState.some(
                                        (item) =>
                                          item.productName === value.name
                                      )}
                                      onChange={handleCheckBox}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: "white", // Default border color
                                          },
                                          "&:hover fieldset": {
                                            borderColor: "white", // Border color on hover
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "white", // Border color when focused
                                          },
                                        },
                                        color: "white",
                                        marginLeft: "2ch",
                                      }}
                                    />
                                  }
                                  label={value.name}
                                />
                              </FormGroup>
                            </div>
                            <div>
                              {checkboxState.map((product, index) => {
                                if (product.productName === value.name) {
                                  return (
                                    <TextField
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: "white", // Default border color
                                          },
                                          "&:hover fieldset": {
                                            borderColor: "white", // Border color on hover
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "white", // Border color when focused
                                          },
                                        },
                                        mb: 2,
                                        color: "white",
                                        marginLeft: "2ch",
                                      }}
                                      InputProps={{
                                        sx: {
                                          color: "white",
                                        },
                                      
                                      }}
                                      InputLabelProps={{
                                        sx: {
                                          color: "white",
                                        },
                                      }}
                                      key={index}
                                      type="number"
                                      inputProps={{
                                        min: 1,
                                        max: 100,
                                      }}
                                      onKeyDown={(e) => {
                                        const allowedKeys = [
                                          "Backspace",
                                          "ArrowLeft",
                                          "ArrowRight",
                                          "Delete",
                                          "Tab",
                                        ];
                                        const allowedCharPattern = /^[0-9]$/;

                                        // Check if the pressed key is not allowed
                                        if (
                                          !allowedKeys.includes(e.key) &&
                                          !allowedCharPattern.test(e.key)
                                        ) {
                                          e.preventDefault(); // Prevent the default action of the disallowed key
                                        }
                                      }}
                                      label={`${product.productName} quantity`}
                                      value={product.quantity}
                                      onChange={(e) => {
                                        handleQuality(e, value.name);
                                      }}
                                    />
                                  );
                                }else{
                                  return(
                                    <></>
                                  )
                                }
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        style={{
                          backgroundColor: "white",
                          color:"black"
                        }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button type="submit" variant="contained">
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </Box>
                  </Box>
                </div>
              ) : (
                <React.Fragment>
                  <div className="personalDetails">
                    <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                      Summary
                    </Typography>
                    <div className="summary">
                      <div>
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                          Personal Details
                        </Typography>
                        <div className="list-item">
                          <p>
                            <strong>Name:</strong>
                            {formData.name}
                          </p>
                          <p>
                            <strong>Email:</strong>
                            {formData.email}
                          </p>
                          <p>
                            <strong>Phone Number:</strong>
                            {formData.phoneNumber}
                          </p>
                        </div>
                      </div>
                      <div>
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                          contact Details
                        </Typography>
                        <div className="list-item">
                          <p>
                            <strong>Address:</strong>
                          </p>
                          <p>
                            <p>
                              <strong>Door No:</strong> {address.doorno}
                            </p>
                            <p>
                              <strong>Street:</strong>
                              {address.street}
                            </p>
                            <p>
                              <strong>Area:</strong> {address.area}
                            </p>
                            {address.landmark && (
                              <p>
                                <strong>Landmark:</strong> {address.landmark}
                              </p>
                            )}
                            <p>
                              <strong>District:</strong> {address.district}
                            </p>
                            <p>
                              <strong>State:</strong> {address.state}
                            </p>
                            <p>
                              <strong>Pincode:</strong> {address.pincode}
                            </p>
                          </p>
                        </div>
                      </div>
                      <div>
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                          Product Details
                        </Typography>
                        <div className="list-item">
                          {" "}
                          <ul>
                            {checkboxState.map((product, index) => {
                              return (
                                <li key={index}>
                                  {product.productName} x {product.quantity}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        variant="contained"
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button variant="contained" onClick={handleBooking}>
                        Finish
                      </Button>
                    </Box>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Box>
      </div>
      {loading && <Loader />}
    </div>
  );
}
