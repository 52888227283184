import { BrowserRouter, Route, Routes  } from "react-router-dom";
import route from "./Components/Common/Route/Route";
import Footer from "./Components/Common/Layout/Footer/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import ScrollToTop from "./Components/Common/Layout/ScrollTop/Scrool";
import { Toaster } from 'react-hot-toast';
import FooterVisibility from "./Components/Common/Layout/FooterVisibility/FooterVisibility";

function App() {

 
  useEffect(() => {
    AOS.init({  duration: 1200});
  }, [])
  return (
    <BrowserRouter>
     <Toaster  toastOptions={{
    success: {
      style: {
        duration: 3000,
        background: 'green',
        color:"white"
      },
    },
    error: {
      style: {
        duration: 3000,
        background: 'red',
        color:"white"
      },
    },
  }}/>
    <ScrollToTop/>
      <Routes>
        {route.map((e,i) => {
          return <Route exact={e.exact===true} key={i} path={e.path} element={e.element}  />;
        })}
      </Routes>
      <FooterVisibility/>

    </BrowserRouter>
  );
}

export default App;
