import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header1 from "../../Common/Layout/Header/Header1";
import { BsWhatsapp } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";
import "./Contact.css";
import client from "../../Common/Client/Client";
import Icons from "../../Common/Icons/Icons";

const Contact = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getFetch();
  }, []);

  const getFetch = async () => {
    try {
      const response = await client.get("/contact/get-contact");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const handleWhatsAppClick = (whatsapp) => {
    window.open(`https://wa.me/${whatsapp}`);
  };
  return (
    <Fragment>
      <div className="sub_page">
        <Header1 />
        <Icons/>
        <section className="contact_section layout_padding" data-aos="zoom-in">
          <div className="container">
            {data.map((item, index) => {
              return (
                <>
                  <div className="heading_container text-center">
                    <h2>Contact Information</h2>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 col-sm-6 mx-auto">
                            <div className="featured-content">
                              <div class="cr">
                                <div className="featured-title">
                                  <h3 class="card-title">ADDRESS</h3>
                                </div>
                                <div
                                  className="featured-desc"
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    padding: "10px",
                                  }}
                                >
                                  <p class="small-desc">
                                   {item.address}
                                  </p>
                                </div>
                                <div class="go-corner">
                                  <div class="go-arrow">→</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 mx-auto">
                            <div className="featured-content">
                              <div class="cr">
                                <div className="featured-title">
                                  <h3 class="card-title">CONTACT NUMBER </h3>
                                </div>
                                <div
                                  className="featured-desc"
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    padding: "10px",
                                  }}
                                >
                                  <p
                                    class="small-desc"
                                    onClick={()=>{handleWhatsAppClick(item.whatsapp)}}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <BsWhatsapp
                                      style={{
                                        fontSize: "30px",
                                        color: "green",
                                      }}
                                    />
                                 <span style={{
                                  fontSize: "16px",
                                  color: "black",
                                  marginLeft:"10px"
                                 }}> {item.whatsapp} </span>
                                  </p>
                                  <p
                                    class="small-desc"
                                    onClick={() => {
                                      window.location.href =
                                        `tel:+91 ${item.phone}`;
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <FaPhone
                                      style={{
                                        fontSize: "30px",
                                        color: "blue",
                                      }}
                                    />
                                    <span style={{
                                  fontSize: "16px",
                                  color: "black",
                                  marginLeft:"10px"
                                 }}> +91 {item.phone} </span>
                                  </p>
                                </div>
                                <div class="go-corner">
                                  <div class="go-arrow">→</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 mx-auto">
                            <div className="featured-content">
                              <div class="cr">
                                <div className="featured-title">
                                  <h3 class="card-title">EMAIL</h3>
                                </div>
                                <div
                                  className="featured-desc"
                                  style={{
                                    fontSize: "16px",
                                    color: "black",
                                    padding: "10px",
                                  }}
                                >
                                  <p class="small-desc">
                                    <Link to="" onClick={()=>{handleEmail(item.email)}}>
                                     {item.email}
                                    </Link>
                                  </p>
                                </div>
                                <div class="go-corner">
                                  <div class="go-arrow">→</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </>
              );
            })}

            <div className="row" style={{ marginTop: "60px" }}>
              <div className="heading_container text-center">
                <h2>Find Us Here</h2>
              </div>

              <div className="col-md-12">
                <div className="map_container">
                  <div className="map">
                    <div id="googleMap">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d690.7021736533327!2d78.12997749501714!3d11.680125489305459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf1cec28ef07f%3A0xd91ff8c42eca7f18!2sVigneshwara%20Meditech%20Disposables!5e0!3m2!1sen!2sin!4v1724051702717!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{
                          border: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default Contact;
