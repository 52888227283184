import { useLocation  } from "react-router-dom";
import Footer from "../Footer/Footer";
function FooterVisibility() {
    const location = useLocation(); // Get current path after wrapping in BrowserRouter
  
    // Define paths where the Footer should be displayed
    const footerPaths = ["/", "/about", "/products", "/products-details", "/booking", "/contact"];
  
    return footerPaths.includes(location.pathname) ? <Footer /> : null;
  }
  
  export default FooterVisibility;