import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import Header1 from "../../Common/Layout/Header/Header1";
import "./Products-details.css";
import { MdMedicalInformation } from "react-icons/md";
import mainImg from '../../../Assets/Image/main.png'
import Icons from "../../Common/Icons/Icons";

const ProductsDetails = () => {
  const location = useLocation();
  const productDetails = location.state;
  let subImages = productDetails.subImages;
  let desvalues = productDetails.desvalue;

  return (
    <Fragment>
      <div className="sub_page">
        <Header1 />
        <Icons/>
        <div className="products" >
          <div className="product-name"  data-aos="zoom-in">
            <h3 style={{
              textAlign:"center",
              fontWeight:"bold"
            }}> {productDetails.name}</h3>
            <div className="products-description">
            <p>{productDetails.description}</p>
            </div>
          </div>
          <div className="producuts-show"  data-aos="zoom-in">
            <div className="products-images">
              <img
                src={productDetails.mainImage}
                alt="product"
              
              />
            </div>
            <div className="products-content">
              {desvalues.map((value, index) => {
                return <p key={index}> <MdMedicalInformation  style={{fontSize:"20px",marginRight:"5px"}}/> {value}</p>;
              })}
            </div>
          </div>
        </div>
         <div className="image-des">
          <p></p>
          <img src={mainImg} alt="" style={{
            border:"1px solid gray"
          }} data-aos="zoom-in"/>
        </div>
      
        <div className="sub-image"  data-aos="zoom-in">
          {subImages.map((images, index) => {
            return <img src={images} alt="" height="200px" width="100%" />;
          })}

        </div>
       
      </div>
    </Fragment>
  );
};

export default ProductsDetails;
