import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Icons.css'
import client from '../Client/Client';

const Icons = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getFetch();
  }, []);

 

  const getFetch = async () => {
    try {
      const response = await client.get("/contact/get-contact");
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (

    <>
    {
      data.map((item, index) => {
        return (
          <div key={index}>
            <Link to=""   onClick={() => {
                            window.location.href = `tel:+91 ${item.phone}`;
                          }} class="btn-phone-pulse btn-phone-pulse-border"  title="Call Now" style={{
        "z-index": "100",
        "margin-bottom": "px"
      }}>
      <i class="fa fa-phone" aria-hidden="true"></i>
      </Link>

      <Link to="" onClick={()=>{
         window.open(`https://wa.me/${item.whatsapp}`);
      }} class="btn-whatsapp-pulse btn-whatsapp-pulse-border" title="Whatsapp Now" style={{
        "z-index": "100",
        "margin-bottom": "px"
      }} >
        <i class="fa-brands fa-whatsapp" aria-hidden="true"></i>

      </Link>
          </div>
        )
      })
    }
    
      </>
  )
}

export default Icons