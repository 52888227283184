import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Header1 from "../../Common/Layout/Header/Header1";
import "./About.css";
import image from "../../../Assets/Image/aboutuspic.jpeg";
import Icons from "../../Common/Icons/Icons";

const About = () => {
  const [content, setContent] = useState(null);
  return (
    <Fragment>
     
      <div className="sub_page">
        <Header1 />
       <Icons/>
        <section
          className="about_section layout_padding"
          data-aos="fade-up"
          style={{ padding: "80px 0" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="img-box">
                  <img
                    src={image}
                    alt="About Us"
                    className="img-fluid rounded"
                    style={{
                      border: "2px solid #ddd",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                     
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="detail-box">
                  <div className="heading_container mb-4">
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        fontWeight: "600",
                        color: "#333",
                      }}
                    >
                      About Us
                    </h2>
                  </div>
                  <p style={{ fontSize: "1.1rem", color: "#555" }}>
                    Welcome to Sri Vignesh Meditech Disposables, where our
                    commitment to excellence drives the innovation of
                    high-quality, disposable medical products. As a leading
                    provider in the healthcare industry, we specialize in
                    offering a comprehensive range of sterile, single-use items
                    designed to enhance safety, ensure infection prevention, and
                    maintain a clean and controlled environment in medical
                    settings.
                  </p>
                  <p style={{ fontSize: "1.1rem", color: "#555" }}>
                    Our extensive product lineup includes nonwoven drapes,
                    gowns, and other essential disposables, all crafted with the
                    utmost attention to quality and performance. We utilize
                    advanced technologies such as ULTRASONIC SEALING to ensure
                    our gowns provide superior fluid resistance and durability,
                    offering reliable protection for both patients and
                    healthcare professionals.
                  </p>
                  {content === 1 && (
                    <p style={{ fontSize: "1.1rem", color: "#555" }}>
                      At Sri Vignesh Meditech Disposables, we understand the
                      critical role that hygiene and infection control play in
                      medical procedures. That’s why we prioritize meticulous
                      manufacturing processes and rigorous quality control
                      measures to deliver products that meet the highest
                      standards of safety and effectiveness.
                    </p>
                  )}
                  <Link
                    to=""
                    onClick={() => setContent(content === 1 ? null : 1)}
                    style={{
                      display: "inline-block",
                      marginTop: "10px",
                      fontSize: "1.1rem",
                      textDecoration: "none",
                      fontWeight: "500",
                    }}
                  >
                    {content === 1 ? "Read Less" : "Read More"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="ttm-row service-section ttm-bgcolor-grey position-relative z-index-2 clearfix"
          data-aos="zoom-in"
          style={{ margin: "0px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title title-style-center_text container">
                  <div className="title-header heading_container heading_center">
                    <h5>why choose us ?</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 mx-auto" style={{
                      marginTop:"10px"
                    }}>
                      <div className="featured-icon-box icon-align-top-content style5 cardss">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Quality Assurance</h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              At Sri Vignesh Wara Meditech, we adhere to the
                              highest standards of quality and safety. Our
                              products are manufactured in state-of-the-art
                              facilities and undergo stringent testing to ensure
                              they meet international standards.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mx-auto"  style={{
                      marginTop:"10px"
                    }}>
                      <div className="featured-icon-box icon-align-top-content style5 cardss">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Innovative Solutions</h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              We leverage the latest technology to develop
                              disposable medical products that enhance both
                              functionality and ease of use. Our commitment to
                              innovation ensures that you have access to the
                              most advanced solutions available.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mx-auto"  style={{
                      marginTop:"10px"
                    }}>
                      <div className="featured-icon-box icon-align-top-content style5 cardss">
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Customer-Centric Approach</h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Our team is dedicated to providing exceptional
                              service and support. From personalized
                              consultations to quick response times, we are here
                              to assist you every step of the way.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>

        <div
        className="certification-container mb-4"
        data-aos="zoom-in"
        style={{ marginTop: "30px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 heading_container heading_center">
              <h2 className=" ">Certifications</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "20px" }}>
              <div className="certification-box " >
                <img src="Assets/images/certificate.jpg" alt="" width="100%"  style={{
                  boxShadow:" rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                }} className="homeImage"/>
              </div>
            </div>
            <div className="col-md-6" style={{ marginTop: "20px" }}>
              <div className="certification-box">
                <img src="Assets/images/test.jpg" alt="" width="100%" style={{
                  boxShadow:" rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                }} className="homeImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default About;
